import 'slick-carousel';
import jQuery from 'jquery';
window.jQuery = window.$ = jQuery;

const benefits = document.querySelector('.js-benefits');

if (benefits) {
  jQuery('.js-benefits').slick({
    arrows: true,
    prevArrow: jQuery('.js-benefits-prev'),
    nextArrow: jQuery('.js-benefits-next'),
    infinite: false
  });
}
